import shoogle from '../scripts/shoogle';
import connectors from '../scripts/connectors';
import pageSizeObservable from '../scripts/lib/page-size-observable';
import getPointInElementPosition from '../scripts/lib/get-point-in-element-position';
import scrollVelocity from '../scripts/scroll-velocity';
import draggable from '../scripts/draggable';
import toArray from '../scripts/lib/to-array';
import { share, Subject, fromEvent, share, filter, mergeMap, takeUntil, interval, take } from 'rxjs'
import intersectionObservable from '../scripts/lib/intersection-observable.js';
import getEdits from '../scripts/edits';
import { getWidthBreakpointObservable, getLatestBreakpoint, getAllBreakpoints } from '../scripts/width-breakpoint-observable.js';
import resizable from '../scripts/resizable';
import makeTypewriter from '../scripts/lib/make-typewriter';
import getVideoPixelsObservable from '../scripts/lib/get-video-pixels-observable.js';
import animationFrameObservable from '../scripts/lib/animation-frame-observable.js';
import randomBetween from '../scripts/lib/random-between.js';
import extractElementName from '../scripts/extract-element-name.js';
import getIsDebug from '../scripts/is-debug.js';
import createDwellObservable from '../scripts/dwell-observable.js';

document.addEventListener('DOMContentLoaded', () => {

    const isDebug = getIsDebug();

    if (isDebug) {
        var body = document.querySelector('body');
        body.classList.add('debug');
    }

    var widthBreakpointObservable$ = getWidthBreakpointObservable();
    var edits$ = getEdits();

    if (isDebug) {
        // Breakpoints debug
        var breakpointEl = document.createElement('div');

        // Set the text content of the div
        breakpointEl.textContent = 'debug';

        // Apply styles to the div
        Object.assign(breakpointEl.style, {
            position: 'fixed',
            bottom: '10px', // Position it at the bottom
            right: '10px',  // Position it at the right
            backgroundColor: 'pink', // Set the background color to pink
            padding: '10px', // Add some padding for better visibility
            zIndex: '5000' // Ensure it stays on top of other elements
        });


        // Append the div to the body
        document.body.appendChild(breakpointEl);

        widthBreakpointObservable$
            .subscribe(breakpoint => {
                breakpointEl.textContent = breakpoint;
            });
    }

    // Cover
    var coverEl = document.querySelector('#cover');
    var coverTextEl = coverEl.querySelector('h1');
    coverEl.remove();
    // var coverSubscription = makeTypewriter({
    //     el: coverTextEl,
    //     strings: [
    //         'Ross Cairns',
    //         'Career Highlights'
    //     ],
    //     onFinishString: (count) => {
    //         if (count === 2) {
    //             coverSubscription.unsubscribe();
    //             setTimeout(() => {
    //                 coverEl.classList.add('removing');
    //                 setTimeout(() => {
    //                     coverEl.remove();
    //                 }, 2000);
    //             }, 333);
    //         }
    //     }
    // }).subscribe();


    // Typewriter

    // var textEl = document.querySelector('#titlewriter');

    // makeTypewriter({
    //     el: textEl,
    //     startWith: textEl.innerText,
    //     strings: [
    //         'Serial Collaborator',
    //         'Dad',
    //         'Nice Guy(?)',
    //         'Coder',
    //         'Design Engineer',
    //         'UX/UI Designer',
    //         'AR Engineer',
    //         'Creative Developer',
    //         'Digital Maker',
    //     ].map(s => s + ',')
    // }).subscribe();

    // Shooglers

    var shooglerHtmlCollection = document.getElementsByTagName('a');

    Array.prototype.forEach.call(
        shooglerHtmlCollection,
        shoogle
    );

    // Connectors
    var connectorsContainer = document.querySelector('#background');
    var div = document.createElement('div');
    connectorsContainer.prepend(div);
    var connectorsProvider = connectors(div);

    // Solo Boxes

    var soloBoxes = toArray(document.getElementsByClassName('solo-box'))
        .forEach((el) => {
            var mediaEl = el.querySelector('img, video');
            makeMedia(mediaEl, el);
            makeHeightAdjustable(el, el);
            makeDwell(el, el.id);
        })

    // Info Boxes

    var infoBoxes = toArray(document.getElementsByClassName('info-box'));

    infoBoxes.forEach(makeInfoBox);

    function makeInfoBox(el) {

        const infoBoxUpdated$ = new Subject();

        makeHeightAdjustable(el.parentNode, el);

        const boxEl = el.querySelector('.box');
        const pulloutEl = el.querySelector('.pullout');

        if (pulloutEl) {
            makePullOut(pulloutEl);
        }

        var layout = getLayout(el);

        // Size editing

        var resizable$ = resizable(boxEl, {
            widthOnly: true,
            preserveAspect: false
        });
        resizable$
            .subscribe(makeEditHandler(el.id, 'info'));
        resizable$
            .subscribe(() => infoBoxUpdated$.next());

        // Position editing

        var infoDragging$ = draggable(boxEl).pipe(share());

        infoDragging$
            .subscribe(makeEditHandler(el.id, 'info'));

        infoDragging$
            .subscribe(() =>
                infoBoxUpdated$.next());

        var pictures = toArray(el.querySelectorAll('.picture'));

        pictures.forEach((pictureEl) => {
            var mediaEl = pictureEl.querySelector('img, video');
            var renderConnector = makeConnection(boxEl, mediaEl);
            makeMedia(mediaEl, el)
                .subscribe(() => {
                    renderConnector();
                });
            infoBoxUpdated$.subscribe(() => {
                renderConnector();
            });
            pageSizeObservable()
                .subscribe(renderConnector);
        });

        // Size and position applying

        widthBreakpointObservable$
            .subscribe(_ => {
                var initialPosition = layout.getElementLayout(getLatestBreakpoint(), 'info');

                if (initialPosition.width) {
                    boxEl.style.width = initialPosition.width + 'px';
                    boxEl.style['max-width'] = initialPosition.width + 'px';
                } else {
                    boxEl.style.removeProperty('width');
                    boxEl.style.removeProperty('max-width');
                }

                boxEl.style.transform = `translate(${initialPosition.x}px, ${initialPosition.y}px)`;
                infoBoxUpdated$.next();

            });


        makeDwell(el, el.id);
    }

    function makeDwell(el, id) {
        createDwellObservable(el, 2000)
            .pipe(
                take(1)
            )
            .subscribe(() => {
                if (!isDebug) {
                    if (umami) {
                        umami.track(id);
                    }
                }
            });
    }

    function makeConnection(boxEl, mediaEl) {

        var elementColorSample = "#0075FF";
        var pixelColorObservable$;
        const strength = 50;
        const offset = 9;
        const zero = new DOMPoint();
        const toOrigin = new DOMPoint();
        const fromOrigin = new DOMPoint();

        const src = mediaEl.getAttribute('src') || mediaEl.querySelector('source').getAttribute('src');
        const isVideo = src.endsWith('.webm');
        const backgroundEl = document.querySelector('#background');

        var connector = connectorsProvider
            .makeConnector();

        const renderConnector = () => {
            const fromTopLeft = getPointInElementPosition(boxEl, zero, new DOMPoint(-1, -1));
            const fromCenter = getPointInElementPosition(boxEl, zero, zero);
            const fromBottomRight = getPointInElementPosition(boxEl, zero, new DOMPoint(1, 1));

            const backgroundOffset = parseInt(window.getComputedStyle(backgroundEl).left);
            const toTopLeft = getPointInElementPosition(mediaEl, zero, new DOMPoint(-1, -1));
            const toCenter = getPointInElementPosition(mediaEl, zero, zero);
            const toBottomRight = getPointInElementPosition(mediaEl, zero, new DOMPoint(1, 1));

            if (fromBottomRight.x < toTopLeft.x) {
                fromOrigin.x = 1;
                fromOrigin.y = 0;
                toOrigin.x = -1;
            } else if (fromTopLeft.x > toBottomRight.x) {
                fromOrigin.x = -1;
                fromOrigin.y = 0;
                toOrigin.x = 1;
            } else {
                fromOrigin.x = 0;
                toOrigin.x = 0;
                if (fromCenter.y < toCenter.y) {
                    fromOrigin.y = 1;
                } else {
                    fromOrigin.y = -1;
                }
                if (toCenter.x > fromCenter.x) {
                    toOrigin.x = -1;
                } else {
                    toOrigin.x = 1;
                }
            }

            const formOffset = getPointInElementPosition(boxEl, new DOMPoint(offset * fromOrigin.x - backgroundOffset, offset * fromOrigin.y), fromOrigin);
            const formControl = getPointInElementPosition(boxEl, new DOMPoint((offset + strength) * fromOrigin.x - backgroundOffset, (offset + strength) * fromOrigin.y), fromOrigin);
            connector.to(formOffset.x, formOffset.y, formControl.x, formControl.y, '#0075FF');

            const toOffset = getPointInElementPosition(mediaEl, new DOMPoint(offset * toOrigin.x - backgroundOffset, offset * toOrigin.y), toOrigin);
            const toControl = getPointInElementPosition(mediaEl, new DOMPoint((offset + strength) * toOrigin.x - backgroundOffset, (offset + strength) * toOrigin.y), toOrigin);

            if (isVideo) {
                // if (!pixelColorObservable$) {
                //     pixelColorObservable$ = getVideoPixelsObservable(mediaEl);
                //     pixelColorObservable$.subscribe(colors => {
                //         if (toOrigin.x > 0.5) {
                //             elementColorSample = `rgb(${colors[1][0]}, ${colors[1][1]}, ${colors[1][2]})`;
                //         } else {
                //             elementColorSample = `rgb(${colors[0][0]}, ${colors[0][1]}, ${colors[0][2]})`;
                //         }
                //         renderConnector();
                //     });
                // }
            } else {
                // const attributeName = 'data-color-' + (toOrigin.x > 0.5 ? 'right' : 'left');
                // elementColorSample = mediaEl.getAttribute(attributeName);
            }

            connector.from(toOffset.x, toOffset.y, toControl.x, toControl.y, elementColorSample);
        };

        // Should be disposable
        setInterval(() => {
            renderConnector();
        }, 1000);

        renderConnector();

        return renderConnector;

    }

    function makePullOut(pulloutEl) {
        const pulloutContent = pulloutEl.querySelector('.pullout-content');

        const originalText = pulloutContent.innerText;
        var typewriter$;

        intersectionObservable(pulloutEl).subscribe(({ isIntersecting }) => {

            if (isIntersecting) {
                if (typewriter$) {
                    typewriter$.unsubscribe();
                    typewriter$ = null;
                }

                typewriter$ = makeTypewriter({
                    delayStart: randomBetween(3000, 4000),
                    loop: false,
                    el: pulloutContent,
                    strings: [
                        originalText
                    ]
                }).subscribe(() => { }, () => { }, () => {
                    setTimeout(() => {
                        pulloutContent.innerText = pulloutContent.innerText.slice(0, -1);
                    }, randomBetween(300, 777));
                });
            } else {
                if (typewriter$) {
                    typewriter$.unsubscribe();
                    typewriter$ = null;
                }
                pulloutContent.innerText = "";
            }
        });

        pulloutContent.innerText = "";
    }

    // Create an observable that listens to keypress events on the document
    const keypress$ = fromEvent(document, 'keypress').pipe(
        share()
    );


    // Hover over the info box or img/video and press u or j to
    // change the size of the container
    function makeHeightAdjustable(el, scopeEl) {

        var didUpdate$ = new Subject();

        var layout = getLayout(scopeEl);

        widthBreakpointObservable$
            .subscribe(_ => {
                var elementLayout = layout.getElementLayout(getLatestBreakpoint(), '::parent');

                if (elementLayout && elementLayout.height) {
                    el.style.height = elementLayout.height + 'px';
                }
            });

        // Create an observable for mouse enter and leave events on the element
        const mouseEnter$ = fromEvent(el, 'mouseenter');
        const mouseLeave$ = fromEvent(el, 'mouseleave');

        // Create an observable that only emits keypress events when the mouse is over the element
        const keypressWhenMouseOver$ = mouseEnter$.pipe(
            mergeMap(() => keypress$.pipe(
                takeUntil(mouseLeave$),
                share()
            ))
        );

        keypressWhenMouseOver$.subscribe(({ key }) => {
            var sizeDelta = 0;
            if (key === "u") {
                sizeDelta = 25;
            } else if (key === "j") {
                sizeDelta -= 25;
            }
            if (!sizeDelta) {
                return;
            }

            var currentHeight = parseFloat(el.style.height);
            if (!currentHeight) {
                currentHeight = 300;
            }

            var height = currentHeight + sizeDelta;

            el.style.height = height + "px";

            // Make edit packet
            var data = {};
            data[scopeEl.id] = {};
            data[scopeEl.id][getLatestBreakpoint()] = {};
            data[scopeEl.id][getLatestBreakpoint()]['::parent'] = { height };

            // Send the edits
            edits$.next(data);

            didUpdate$.next();
        });

        return didUpdate$;
    }

    function makeMedia(mediaEl, scopeEl) {
        var didUpdate$ = new Subject();

        const src = mediaEl.getAttribute('src') || mediaEl.querySelector('source').getAttribute('src');
        var elementName = extractElementName(src);
        var layout = getLayout(scopeEl);

        // Size and position applying

        const originalWidth = mediaEl.style.width;
        const originalHeight = mediaEl.style.height;

        widthBreakpointObservable$
            .subscribe(_ => {
                var initialPosition = layout.getElementLayout(getLatestBreakpoint(), elementName);

                mediaEl.style.transform = `translate(${initialPosition.x}px, ${initialPosition.y}px)`;

                if (initialPosition.width) {
                    mediaEl.style.width = initialPosition.width + 'px';
                } else {
                    mediaEl.style.width = originalWidth;
                }
                if (initialPosition.height) {
                    mediaEl.style.height = initialPosition.height + 'px';
                } else {
                    mediaEl.style.height = originalHeight;
                }
            });

        draggable(mediaEl)
            .subscribe((v) => {

                // Make edit packet
                var data = {}
                data[scopeEl.id] = {};
                data[scopeEl.id][getLatestBreakpoint()] = {};
                data[scopeEl.id][getLatestBreakpoint()][elementName] = v;

                // Send the edits
                edits$.next(data);

                didUpdate$.next();
            });

        // Resizing

        resizable(mediaEl)
            .subscribe(v => {
                // Make edit packet
                var data = {}
                data[scopeEl.id] = {};
                data[scopeEl.id][getLatestBreakpoint()] = {};
                data[scopeEl.id][getLatestBreakpoint()][elementName] = v;
                // Send the edits
                edits$.next(data);

                didUpdate$.next();
            });

        return didUpdate$;
    }

    function makeEditHandler(id, elementName) {
        return (v) => {

            // Make edit packet
            var data = {}
            data[id] = {};
            data[id][getLatestBreakpoint()] = {};
            data[id][getLatestBreakpoint()][elementName] = v;

            // Send the edits
            edits$.next(data);
        }
    }

});

// Utils

function getLayout(el) {
    var layout = el.getAttribute('data-layout');
    try {
        layout = JSON.parse(layout);
    } catch (err) {
        layout = {};
    }

    if (layout === null) {
        layout = {};
    }

    var breakpoints = Array.from(getAllBreakpoints().keys());

    function getPreviousBreakpoint(currentKey) {
        const currentIndex = breakpoints.indexOf(currentKey);
        if (currentIndex > 0) {
            return breakpoints[currentIndex - 1];
        } else {
            return null; // or handle the case where there is no previous element
        }
    }

    function getElementLayout(breakpointName, elementName) {
        if (layout[breakpointName] && layout[breakpointName][elementName]) {
            return layout[breakpointName][elementName];
        }

        const previousBreakpoint = getPreviousBreakpoint(breakpointName);

        if (!previousBreakpoint) {
            return {
                x: 0,
                y: 0,
                width: null,
                height: null
            };
        } else {
            return getElementLayout(previousBreakpoint, elementName);
        }
    }

    return {
        getElementLayout
    }
}

function getTranslateValues(element) {
    const style = window.getComputedStyle(element);
    const transform = style.transform;

    // Check if the transform is none or not specified
    if (!transform || transform === 'none') {
        return { x: 0, y: 0 };
    }

    // Use a regular expression to extract the translate values
    const match = transform.match(/translate\(\s*([^\s,)]+)[ ,]([^\s,)]+)/);
    if (match) {
        return {
            x: parseFloat(match[1]),
            y: parseFloat(match[2])
        };
    } else {
        return { x: 0, y: 0 };
    }
}



// function measureRepaints() {
//     let previousTimestamp = performance.now();

//     function animate(timestamp) {
//         const timeSinceLastPaint = timestamp - previousTimestamp;
//         previousTimestamp = timestamp;

//         // Log the time between repaints
//         console.log(`Time since last paint: ${timeSinceLastPaint.toFixed(2)} ms`);

//         // Your animation code here...

//         // Request the next frame
//         requestAnimationFrame(animate);
//     }

//     // Start the animation loop
//     requestAnimationFrame(animate);
// }

// measureRepaints();


// animationFrameObservable().subscribe(v => {
//     console.log("Update");
// })

console.log("👋");